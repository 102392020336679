<template>
    <DxValidationGroup ref="formValidation">
        <div class="row font-20 font-medium justify-center mb-3">
            Thông tin tài khoản hóa đơn điện tử
        </div>
        <div class="row mb-5">
            <div class="xs12">
                <DxTextBox
                    v-model="ThongTinTaiKhoan.taiKhoanHeThong"
                    label="Tài khoản hệ thống QLBX (*)"
                    labelMode="floating"
                    styling-mode="outlined"
                    validationMessageMode="always"
                    :readOnly="
                        !$Helper.KiemTraQuyen(
                            $t('Quyen.CauHinh'),
                            $t('Quyen.QuanLyTaiKhoanHoaDonDienTu')
                        )
                    "
                >
                    <DxValidator>
                        <DxRequiredRule
                            message="Tài khoản hệ thống QLBX không được bỏ trống!"
                        />
                    </DxValidator>
                </DxTextBox>
            </div>
        </div>
        <div class="row mb-5">
            <div class="xs12">
                <DxTextBox
                    v-model="ThongTinTaiKhoan.taiKhoanHDDT"
                    label="Tài khoản hóa đơn điện tử (*)"
                    labelMode="floating"
                    styling-mode="outlined"
                    validationMessageMode="always"
                >
                    <DxValidator>
                        <DxRequiredRule
                            message="Tài khoản hóa đơn điện tử không được bỏ trống!"
                        />
                    </DxValidator>
                </DxTextBox>
            </div>
        </div>
        <div class="row mb-5">
            <div class="xs12">
                <DxTextBox
                    v-model="ThongTinTaiKhoan.matKhauHDDT"
                    label="Mật khẩu hóa đơn điện tử (*)"
                    labelMode="floating"
                    styling-mode="outlined"
                    validationMessageMode="always"
                    :mode="modeMatKhau"
                    :buttons="[
                        {
                            options: btnMatKhau,
                            name: 'password',
                            location: 'after',
                        },
                    ]"
                >
                    <DxValidator>
                        <DxRequiredRule
                            message="Mật khẩu hóa đơn điện tử không được bỏ trống!"
                        />
                    </DxValidator>
                </DxTextBox>
            </div>
        </div>
        <div class="row mb-5">
            <div class="xs12">
                <DxTextBox
                    v-model="ThongTinTaiKhoan.compareMatKhauHDDT"
                    label="Nhập lại mật khẩu (*)"
                    labelMode="floating"
                    styling-mode="outlined"
                    validationMessageMode="always"
                    :mode="modeNhapLaiMatKhau"
                    :buttons="[
                        {
                            options: btnNhapLaiMatKhau,
                            name: 'password',
                            location: 'after',
                        },
                    ]"
                >
                    <DxValidator>
                        <DxRequiredRule
                            message="Nhập lại mật khẩu không được bỏ trống!"
                        />
                        <DxCompareRule
                            :comparison-target="
                                () => {
                                    return ThongTinTaiKhoan.matKhauHDDT;
                                }
                            "
                            message="Mật khẩu nhập lại không khớp!"
                        />
                    </DxValidator>
                </DxTextBox>
            </div>
        </div>
    </DxValidationGroup>

    <div class="row justify-space-between">
        <DxButton type="default" text="HỦY" styling-mode="text" @click="hiddenPopup" />
        <DxButton
            type="default"
            :text="(!ThongTinTaiKhoan.idCauHinh ? 'Thêm' : 'Sửa') + ' thông tin'"
            styling-mode="container"
            @click="LuuThongTin()"
        />
    </div>
</template>
<script>
import { DxTextBox, DxValidator, DxValidationGroup, DxButton } from "devextreme-vue";
import { DxPatternRule, DxRangeRule } from "devextreme-vue/validator";
import {
    DxStringLengthRule,
    DxRequiredRule,
    DxCompareRule,
} from "devextreme-vue/data-grid";
export default {
    components: {
        DxTextBox,
        DxValidator,
        DxValidationGroup,
        DxButton,
        DxPatternRule,
        DxRangeRule,
        DxStringLengthRule,
        DxRequiredRule,
        DxCompareRule,
    },
    props: {
        item: { type: Object, default: {} },
    },
    data() {
        return {
            ThongTinTaiKhoan: {
                idCauHinh: null,
                taiKhoanHeThong: null,
                taiKhoanHDDT: null,
                matKhauHDDT: null,
                compareMatKhauHDDT: null,
            },
            modeMatKhau: "password",
            btnMatKhau: {
                icon: "mdi mdi-eye",
                type: "normal",
                onClick: () => {
                    this.modeMatKhau = this.modeMatKhau === "text" ? "password" : "text";
                },
            },
            modeNhapLaiMatKhau: "password",
            btnNhapLaiMatKhau: {
                icon: "mdi mdi-eye",
                type: "normal",
                onClick: () => {
                    this.modeNhapLaiMatKhau =
                        this.modeNhapLaiMatKhau === "text" ? "password" : "text";
                },
            },
        };
    },
    methods: {
        hiddenPopup() {
            this.$emit("hiddenPopup", "PopupThongTinTaiKhoanHoaDonDienTu");
        },
        validate() {
            return this.$refs.formValidation.instance.validate();
        },
        async LuuThongTin() {
            if (!this.validate().isValid) {
                this.$Helper.Common.autoFocusError(this.validate());
            } else {
                try {
                    let action = this.ThongTinTaiKhoan.idCauHinh ? "Sửa" : "Thêm";
                    let rs = await this.$Events.RequestPost({
                        keyRequest: "CapNhatCauHinhTaiKhoanKetNoiHddt",
                        ServicesBE: "CongHoaDonDienTu",
                        url: this.$t("urlCHDDT.CapNhatCauHinhTaiKhoanKetNoiHddt"),
                        dataPost: {
                            idCauHinh: this.ThongTinTaiKhoan.idCauHinh,
                            taiKhoanHeThong: this.ThongTinTaiKhoan.taiKhoanHeThong,
                            taiKhoanHDDT: this.ThongTinTaiKhoan.taiKhoanHDDT,
                            matKhauHDDT: this.ThongTinTaiKhoan.matKhauHDDT,
                        },
                        msgSuccess:
                            action + " thông tin tài khoản hóa đơn điện tử thành công!",
                        msgError:
                            action + " thông tin tài khoản hóa đơn điện tử thất bại!",
                        msgCatch:
                            action + " thông tin tài khoản hóa đơn điện tử thất bại!",
                        typeNotificationSuccess: "Toast",
                        typeNotificationError: "Popup",
                    });
                    if (rs.status) {
                        this.$emit("hiddenPopup", "PopupThongTinTaiKhoanHoaDonDienTu");
                        this.$emit("LayDanhSachTaiKhoanHoaDonDienTu");
                    }
                } catch (error) {
                    console.log("🚀 ~ error:", error);
                }
            }
        },
    },
    created() {
        if (this.item.idCauHinh) {
            this.ThongTinTaiKhoan = {
                idCauHinh: this.item.idCauHinh,
                taiKhoanHeThong: this.item.tenTaiKhoanHeThong,
                taiKhoanHDDT: this.item.tenTaiKhoanHDDT,
                matKhauHDDT: this.item.matKhauHDDT,
                compareMatKhauHDDT: this.item.matKhauHDDT,
            };
        } else {
            this.ThongTinTaiKhoan.taiKhoanHeThong = this.$Core.Auth.TokenParsed.preferred_username;
        }
    },
    mounted() {},
};
</script>
<style scoped></style>
